import { Provider } from "react-redux";
import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import store from "./store";
import { loadUser } from "./actions/auth";
import PrivateRoute from "./common/PrivateRoute";
import setAuthToken from "./common/setAuthToken";

import Alert from "./components/layout/Alert";
import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";

import Login from "./components/auth/Login";
import Register from "./components/auth/Register";

import Price from "./components/forms/Price";
import Booking from "./components/forms/Booking";
import Payment from "./components/forms/Payment";
import Dashboard from "./components/dashboard/Dashboard";

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const App = () => {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <Fragment>
                    <Navbar />
                    <Route exact path="/" component={Landing} />
                    <section className="container">
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <PrivateRoute exact path="/price" component={Price} />
                            <PrivateRoute exact path="/booking" component={Booking} />
                            <PrivateRoute exact path="/payment" component={Payment} />
                            <PrivateRoute exact path="/dashboard" component={Dashboard} />
                        </Switch>
                        <Alert />
                    </section>
                </Fragment>
            </Router>
        </Provider>
    );
};

export default App;
