import { LOGOUT, USER_LOADED, AUTH_FAIL, AUTH_SUCCESS } from "../actions/types";

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOADED:
            return { ...state, isAuthenticated: true, loading: false, user: payload };

        case AUTH_SUCCESS:
            localStorage.setItem("token", payload);
            return { ...state, ...payload, isAuthenticated: true, loading: false };

        case LOGOUT:
        case AUTH_FAIL:
            localStorage.removeItem("token");
            return { ...state, token: null, isAuthenticated: false, loading: false };

        default:
            return state;
    }
}
