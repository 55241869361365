import axios from "axios";

import { setAlert } from "./alert";
import { PRICE_API } from "../common/apiRoutes";
import { GET_PRICE, PRICE_ERROR } from "./types";

export const fetchPrice = (priceid) => async (dispatch) => {
    try {
        if (priceid) {
            const res = await axios.get(`${PRICE_API}/${priceid}`);
            dispatch({ type: GET_PRICE, payload: res.data.price });
        } else {
            const res = await axios.get(PRICE_API);
            dispatch({ type: GET_PRICE, payload: res.data.price[0] });
        }
    } catch (err) {
        dispatch(setAlert(err.response.data.error, "danger"));
        dispatch({ type: PRICE_ERROR });
    }
};

export const updatePrice = (formData, history) => async (dispatch) => {
    try {
        const config = { headers: { "Content-Type": "application/json" } };
        const res = await axios.post(PRICE_API, formData, config);
        dispatch(setAlert("Price Updated", "success"));
        dispatch({ type: GET_PRICE, payload: res.data.price });
        history.push("/dashboard");
    } catch (err) {
        dispatch(setAlert(err.response.data.error, "danger"));
        dispatch({ type: PRICE_ERROR });
    }
};
