export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SUCCESS = "AUTH_SUCCESS";

export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";

export const FETCH_ID = "FETCH_ID";
export const GET_BOOKING = "GET_BOOKING";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const CLEAR_BOOKINGS = "CLEAR_BOOKINGS";
export const BOOKINGS_LOADED = "BOOKINGS_LOADED";

export const GET_PRICE = "GET_PRICE";
export const PRICE_ERROR = "PRICE_ERROR";
