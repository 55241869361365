import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, Fragment } from "react";

import Bookings from "./Bookings";
import Spinner from "../layout/Spinner";
import { fetchBookings } from "../../actions/booking";

const Dashboard = ({ auth: { user }, booking: { bookings, loading }, fetchBookings }) => {
    useEffect(() => {
        fetchBookings();
    }, [fetchBookings]);

    return loading && bookings.length === 0 ? (
        <Spinner />
    ) : (
        <Fragment>
            <section className="container">
                <h1 className="large text-primary">Dashboard</h1>
                <p className="lead">
                    <i className="fas fa-user" /> Welcome {user && user.name}
                </p>{" "}
                <div className="dash-buttons">
                    <Link to="/booking" className="btn btn-primary my-1">
                        <i className="far fa-calendar-plus" /> New Booking
                    </Link>
                    <Link to="/payment" className="btn btn-success my-1">
                        <i className="far fa-credit-card" /> Add Payment
                    </Link>
                    {user && user.role === "admin" ? (
                        <Link to="/price" className="btn btn-dark my-1">
                            <i className="fas fa-receipt" /> Update Price
                        </Link>
                    ) : (
                        ""
                    )}
                </div>
            </section>
            <Bookings bookings={bookings} />
        </Fragment>
    );
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    booking: PropTypes.object.isRequired,
    fetchBookings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    booking: state.booking,
});

export default connect(mapStateToProps, { fetchBookings })(Dashboard);
