import { combineReducers } from "redux";

import auth from "./auth";
import alert from "./alert";
import price from "./price";
import booking from "./booking";

export default combineReducers({
    auth,
    alert,
    price,
    booking,
});
