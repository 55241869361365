import Moment from "react-moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

import { fetchBooking } from "../../actions/booking";

const Bookings = ({ bookings, fetchBooking, history }) => {
    const allBookings = bookings.map((b) => (
        <tr key={b.estimateid}>
            <td
                onClick={() => {
                    fetchBooking(b.estimateid, history);
                }}
            >
                {" "}
                <Link to="#">{b.estimateid}</Link>
            </td>

            <td>{b.bookingid}</td>
            <td>{b.customer.name}</td>
            <td>
                <Moment format="DD/MM/YYYY">{b.program.programdate}</Moment>
            </td>
            <td>₹{Number(b.total.balance).toLocaleString("hi")}</td>
        </tr>
    ));
    return allBookings.length > 0 ? (
        <Fragment>
            <h2 className="my-2">Bookings</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>E. ID</th>
                        <th>B. ID</th>
                        <th>Name</th>
                        <th>ProgramDt.</th>
                        <th>Balance</th>
                    </tr>
                </thead>
                <tbody>{allBookings}</tbody>
            </table>
        </Fragment>
    ) : (
        <Fragment>
            <p>No Bookings found.</p>
        </Fragment>
    );
};

Bookings.propTypes = {
    bookings: PropTypes.array.isRequired,
    fetchBooking: PropTypes.func.isRequired,
};

export default connect(null, { fetchBooking })(withRouter(Bookings));
