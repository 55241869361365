import { GET_PRICE, PRICE_ERROR } from "../actions/types";

const initialState = { price: null, loading: true };

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_PRICE:
            return { ...state, price: payload, loading: false };
        case PRICE_ERROR:
            return { ...state, price: null, loading: false };
        default:
            return { ...state };
    }
}
